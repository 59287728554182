<template>
  <div>
    <el-dialog
      :title="title"
      :before-close="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      width="90%"
    >
      <div v-if="this.types == 4" style="margin: 10px 0px">
        <el-button @click="cancelForm">取 消</el-button>
        <el-button type="primary" @click="processReviewButton(3)"
          >退回</el-button
        >
        <!-- <el-button type="primary" @click="processReviewButton(4)"
          >拒绝</el-button
        > -->
        <el-button type="primary" @click="processReviewButton(5)"
          >确定</el-button
        >
      </div>
      <el-form
        :model="processReviewFrom"
        label-width="100px"
        ref="processReviewFrom"
        v-if="this.types == 4 || this.types == 3 || this.types == 2"
      >
        <el-row :gutter="24" v-if="this.types == 4">
          <el-col :md="6" :sm="8">
            <el-form-item label="审核意见" prop="opinion">
              <el-input v-model="processReviewFrom.opinion" prop="opinion" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <h2
        v-if="
          this.addEditForm.opinion != null && this.addEditForm.reviewStatus == 3
        "
        style="margin: 10px 40px; color: red"
      >
        {{ this.addEditForm.opinion }}
      </h2>
      <h2
        v-if="this.types != 3 || this.types != 4"
        style="margin: 10px 40px; color: red"
      >
        需要将明细里面的备注填好，尤其是打车的原因地址
      </h2>

      <el-form :model="addEditForm" label-width="100px" ref="addEditForm">
        <el-row :gutter="24">
          <el-col :md="6" :sm="8">
            <el-form-item label="流程编码" prop="code">
              <el-input v-model="addEditForm.code" prop="code" disabled />
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label="报销日期" prop="times">
              <el-date-picker
                v-model="addEditForm.times"
                value-format="yyyy-MM-dd"
                type="date"
                disabled
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <!-- <el-col :md="12" :sm="8">
            <el-form-item label="标题" prop="title">
              <el-input
                placeholder="请输入标题"
                v-model="addEditForm.title"
                prop="title"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col> -->
        </el-row>

        <el-row :gutter="24">
          <el-col :md="6" :sm="8">
            <el-form-item label="报销归属" prop="category">
              <el-radio
                v-model="addEditForm.category"
                :label="1"
                :disabled="this.types == 3 || this.types == 4"
                >项目制</el-radio
              >
              <el-radio v-model="addEditForm.category" :label="2" disabled
                >其他</el-radio
              >
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label="报销类型" prop="type">
              <el-radio-group
                v-model="addEditForm.type"
                @change="dealTypeButton"
              >
                <el-radio
                  :label="1"
                  :disabled="this.types == 3 || this.types == 4"
                  >自己</el-radio
                >
                <el-radio :label="2" disabled>他人</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :md="12" :sm="8">
            <el-form-item label="报销开始日期" prop="timeSolt">
              <el-date-picker
                v-model="addEditForm.timeSolt"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                :disabled="this.types != 1"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :md="6" :sm="8">
            <el-form-item label="项目归属" prop="projectCode">
              <el-select
                v-model="addEditForm.projectCode"
                filterable
                placeholder="请选择"
                style="width: 70%"
                value-key="code"
                @change="getProjectButton"
                :disabled="
                  addEditForm.id != null || this.types == 3 || this.types == 4
                "
              >
                <el-option
                  v-for="item in projectCodeList"
                  :key="item.code"
                  :label="item.name"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label="报销银行" prop="expenseBank">
              <el-input
                :disabled="this.types == 3 || this.types == 4"
                placeholder="请输入报销银行:adj 建设银行"
                v-model="addEditForm.expenseBank"
                prop="expenseBank"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
          <el-col :md="12" :sm="8">
            <el-form-item label="报销账户" prop="expenseAccount">
              <el-input
                :disabled="this.types == 3 || this.types == 4"
                placeholder="请输入报销账户"
                v-model="addEditForm.expenseAccount"
                prop="expenseAccount"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
          <el-col :md="12" :sm="8">
            <el-form-item label="开户行信息" prop="expenseInfo">
              <el-input
                :disabled="this.types == 3 || this.types == 4"
                placeholder="请输入开户行信息"
                v-model="addEditForm.expenseInfo"
                prop="expenseInfo"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>

          <el-col :md="6" :sm="8" v-if="this.addEditForm.type == 2">
            <el-form-item label="报销人" prop="reimburseBy">
              <el-select
                v-model="addEditForm.reimburseBy"
                filterable
                placeholder="请选择"
                style="width: 70%"
              >
                <el-option
                  v-for="item in reimburseByList"
                  :key="item.key"
                  :label="item.value"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :md="12" :sm="8" v-if="this.addEditForm.type == 2">
            <el-form-item label="电话" prop="phone">
              <el-input
                placeholder="请输入电话"
                v-model="addEditForm.phone"
                prop="phone"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div>
        <el-button
          style="margin: 0px 20px"
          disabled
          v-if="this.types == 1 || this.types == 2"
          >一键获取</el-button
        >
        <a
          class="Abutton"
          href="http://175.178.33.21:9000/xun/template/projectReimbursement.xlsx"
          v-if="this.types == 1 || this.types == 2"
          >下载模板</a
        >
        <label
          for="upload"
          class="custom-upload-button"
          v-if="this.types == 1 || this.types == 2"
        >
          <button type="button">上传文件</button>
          <input
            id="upload"
            type="file"
            ref="fileInput"
            @change="handleExcelFile"
          />
        </label>
      </div>

      <!-- 这里改造 -->
      <div style="margin: 10px 20px">
        <el-descriptions :column="4" border>
          <el-descriptions-item
            label="总费用"
            label-class-name="my-label"
            content-class-name="my-content"
            :span="4"
            >{{ this.addMoney }}</el-descriptions-item
          >
        </el-descriptions>
        <el-descriptions :column="4" border>
          <el-descriptions-item
            label="住宿费"
            label-class-name="my-label"
            content-class-name="my-content"
            >{{ this.stayAllMoney }}</el-descriptions-item
          >
          <el-descriptions-item label="市内交通">{{
            this.trafficAllMoney
          }}</el-descriptions-item>
          <el-descriptions-item label="其他费">{{
            this.otherAllMoney
          }}</el-descriptions-item>
          <el-descriptions-item label="伙食费"
            >{{ this.subsidyAllMoney }}
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <el-collapse
        v-model="activeNames"
        @change="handleChangeTab"
        style="margin: 10px 20px"
      >
        <el-collapse-item name="1">
          <template slot="title">
            交通明细<el-button
              class="el-icon-plus"
              round
              size="mini"
              style="margin: 0px 10px"
              @click="trafficAddButton"
              :disabled="this.types == 3 || this.types == 4"
            />
          </template>
          <el-table :data="trafficDetailList" stripe>
            <el-table-column label="序号" type="index" align="center" />
            <el-table-column
              label="操作"
              prop="trafficMoney"
              width="70"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  icon="el-icon-minus"
                  round
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  @click.native.prevent="
                    deleteRow(scope.$index, trafficDetailList, scope.row)
                  "
                />
              </template>
            </el-table-column>
            <el-table-column
              label="费用类型"
              prop="costType"
              align="center"
              width="200"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.costType"
                  filterable
                  placeholder="请选择"
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                >
                  <el-option
                    v-for="item in trafficExpenseTypeList"
                    :key="item.key"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="时间"
              prop="startTime"
              align="center"
              width="450"
            >
              <template slot-scope="scope">
                <el-date-picker
                  v-model="scope.row.startTimes"
                  align="right"
                  type="datetime"
                  placeholder="选择日期"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm"
                  style="width: 80%"
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                >
                </el-date-picker>
              </template>
            </el-table-column>
            <el-table-column
              label="发票类型"
              prop="invoiceType"
              align="center"
              width="300"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.invoiceType"
                  filterable
                  placeholder="请选择"
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                >
                  <el-option
                    v-for="item in invoiceTypeList"
                    :key="item.key"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="发票日期"
              prop="invoiceData"
              align="center"
              width="300"
            >
              <template slot-scope="scope">
                <el-date-picker
                  v-model="scope.row.invoiceData"
                  align="right"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                  style="width: 80%"
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                >
                </el-date-picker>
              </template>
            </el-table-column>
            <el-table-column
              label="发票号码"
              prop="invoiceNumber"
              align="center"
              width="160"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  v-model="scope.row.invoiceNumber"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="发票金额"
              prop="invoiceMoney"
              align="center"
              width="160"
            >
              <template slot-scope="scope">
                <el-input
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  v-model="scope.row.invoiceMoney"
                  type="number"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="报销金额"
              prop="realityMoney"
              align="center"
              width="160"
            >
              <template slot-scope="scope">
                <el-input
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  v-model="scope.row.realityMoney"
                  type="number"
                  @input="moneyAllButton(trafficDetailList, 'traffic')"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="出发地"
              prop="startAddress"
              align="center"
              width="400"
            >
              <template slot-scope="scope">
                <el-input
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  v-model="scope.row.startAddress"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="目的地"
              prop="endAddress"
              align="center"
              width="400"
            >
              <template slot-scope="scope">
                <el-input
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  v-model="scope.row.endAddress"
                />
              </template>
            </el-table-column>

            <!-- <el-table-column
              label="分摊人"
              prop="days"
              align="center"
              width="160"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.type"
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in projectCodeList"
                    :key="item.key"
                    :label="item.value"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="分摊金额"
              prop="money"
              align="center"
              width="140"
            >
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row.money"
                  width="20px"
                  size="small"
                  controls-position="right"
                  :precision="2"
                  :step="0.1"
                />
              </template>
            </el-table-column> -->
            <el-table-column
              label="备注"
              prop="remark"
              align="center"
              width="400"
            >
              <template slot-scope="scope">
                <el-input
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  v-model="scope.row.remark"
                  placeholder="请输入详细原因"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="附件"
              prop="frequency"
              align="center"
              width="200"
              fixed="right"
            >
              <!--   action="https://jsonplaceholder.typicode.com/posts/" -->
              <template slot-scope="scope">
                <el-upload
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  class="upload-demo"
                  action=""
                  :on-remove="(file) => handleRemove(file, scope.row)"
                  :before-remove="beforeRemove"
                  :http-request="
                    (params) => handleFileChange(params, scope.row)
                  "
                  multiple
                  :file-list="scope.row.fileList || []"
                  :limit="6"
                  :on-exceed="handleExceed"
                  list-type="picture"
                >
                  <el-button size="mini" icon="el-icon-plus" round />
                </el-upload>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template slot="title">
            住宿明细<el-button
              class="el-icon-plus"
              round
              size="mini"
              style="margin: 0px 10px"
              @click="stayAddButton"
              :disabled="this.types == 3 || this.types == 4"
            />
          </template>
          <el-table :data="stayDetailList" stripe>
            <el-table-column label="序号" type="index" align="center" />
            <el-table-column
              label="操作"
              prop="trafficMoney"
              width="70"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  icon="el-icon-minus"
                  round
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  @click.native.prevent="
                    deleteRow(scope.$index, stayDetailList, scope.row)
                  "
                />
              </template>
            </el-table-column>
            <el-table-column
              label="费用类型"
              prop="costType"
              align="center"
              width="200"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.costType"
                  filterable
                  placeholder="请选择"
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                >
                  <el-option
                    v-for="item in stayExpenseTypeList"
                    :key="item.key"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="时间"
              prop="timeSolt"
              align="center"
              width="400"
            >
              <template slot-scope="scope">
                <el-date-picker
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  v-model="scope.row.timeSolt"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </template>
            </el-table-column>
            <el-table-column
              label="天数"
              prop="days"
              align="center"
              width="140"
            >
              <template slot-scope="scope">
                <el-input
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  type="number"
                  v-model="scope.row.days"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="发票类型"
              prop="invoiceType"
              align="center"
              width="300"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.invoiceType"
                  filterable
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in invoiceTypeList"
                    :key="item.key"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="发票日期"
              prop="invoiceData"
              align="center"
              width="300"
            >
              <template slot-scope="scope">
                <el-date-picker
                  v-model="scope.row.invoiceData"
                  align="right"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                  style="width: 80%"
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                >
                </el-date-picker>
              </template>
            </el-table-column>
            <el-table-column
              label="发票号码"
              prop="invoiceNumber"
              align="center"
              width="160"
            >
              <template slot-scope="scope">
                <el-input
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  type="number"
                  v-model="scope.row.invoiceNumber"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="发票金额"
              prop="invoiceMoney"
              align="center"
              width="160"
            >
              <template slot-scope="scope">
                <el-input
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  type="number"
                  v-model="scope.row.invoiceMoney"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="报销金额"
              prop="realityMoney"
              align="center"
              width="160"
            >
              <template slot-scope="scope">
                <el-input
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  v-model="scope.row.realityMoney"
                  type="number"
                  @input="moneyAllButton(stayDetailList, 'stay')"
                />
              </template>
            </el-table-column>

            <!-- <el-table-column
              label="分摊人"
              prop="days"
              align="center"
              width="160"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.type"
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in projectCodeList"
                    :key="item.key"
                    :label="item.value"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="分摊金额"
              prop="money"
              align="center"
              width="140"
            >
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row.money"
                  width="20px"
                  size="small"
                  controls-position="right"
                  :precision="2"
                  :step="0.1"
                />
              </template>
            </el-table-column> -->
            <el-table-column
              label="备注"
              prop="remark"
              align="center"
              width="400"
            >
              <template slot-scope="scope">
                <el-input
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  v-model="scope.row.remark"
                  placeholder="需要输入详细原因"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="附件"
              prop="frequency"
              align="center"
              width="200"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-upload
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  class="upload-demo"
                  action=""
                  :on-remove="(file) => handleRemove(file, scope.row)"
                  :before-remove="beforeRemove"
                  :http-request="
                    (params) => handleFileChange(params, scope.row)
                  "
                  multiple
                  :file-list="scope.row.fileList || []"
                  :limit="6"
                  :on-exceed="handleExceed"
                  list-type="picture"
                >
                  <el-button size="mini" icon="el-icon-plus" round />
                </el-upload>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
        <el-collapse-item name="3">
          <template slot="title">
            补助明细<el-button
              class="el-icon-plus"
              round
              size="mini"
              style="margin: 0px 10px"
              @click="subsidyAddButton"
              :disabled="this.types == 3 || this.types == 4"
            />
          </template>
          <el-table :data="subsidyDetailList" stripe>
            <el-table-column label="序号" type="index" align="center" />
            <el-table-column
              label="操作"
              prop="trafficMoney"
              width="70"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  icon="el-icon-minus"
                  round
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  @click.native.prevent="
                    deleteRow(scope.$index, subsidyDetailList, scope.row)
                  "
                />
              </template>
            </el-table-column>
            <el-table-column
              label="费用类型"
              prop="costType"
              align="center"
              width="200"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.costType"
                  filterable
                  placeholder="请选择"
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                >
                  <el-option
                    v-for="item in subsidyExpenseTypeList"
                    :key="item.key"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="时间"
              prop="timeSolt"
              align="center"
              width="400"
            >
              <template slot-scope="scope">
                <el-date-picker
                  v-model="scope.row.timeSolt"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  @change="
                    (val) =>
                      handleDateChange(
                        scope.row,
                        val,
                        scope.$index,
                        subsidyDetailList
                      )
                  "
                >
                </el-date-picker>
              </template>
            </el-table-column>
            <el-table-column label="天数" prop="days" align="center">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.days"
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  type="number"
                  @input="subsidyDaysButton(scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="是否含纸质发票"
              prop="isRaper"
              align="center"
              width="160"
            >
              <template slot-scope="scope">
                <el-checkbox
                  v-model="scope.row.isRaper"
                  :true-label="1"
                  :false-label="2"
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  >是</el-checkbox
                >
              </template>
            </el-table-column>

            <el-table-column
              label="报销金额"
              prop="realityMoney"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  v-model="scope.row.realityMoney"
                  type="number"
                  @input="moneyAllButton(subsidyDetailList, 'subsidy')"
                />
              </template>
            </el-table-column>
            <el-table-column label="备注" prop="remark" align="center">
              <template slot-scope="scope">
                <el-input
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  v-model="scope.row.remark"
                  placeholder="请输入详细原因"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="附件"
              prop="frequency"
              align="center"
              width="200"
            >
              <template slot-scope="scope">
                <el-upload
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  class="upload-demo"
                  action=""
                  :on-remove="(file) => handleRemove(file, scope.row)"
                  :before-remove="beforeRemove"
                  :http-request="
                    (params) => handleFileChange(params, scope.row)
                  "
                  multiple
                  :file-list="scope.row.fileList || []"
                  :limit="6"
                  :on-exceed="handleExceed"
                  list-type="picture"
                >
                  <el-button size="mini" icon="el-icon-plus" round />
                </el-upload>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
        <el-collapse-item name="4">
          <template slot="title">
            其他明细<el-button
              class="el-icon-plus"
              round
              size="mini"
              style="margin: 0px 10px"
              @click="otherAddButton"
              :disabled="this.types == 3 || this.types == 4"
            />
          </template>

          <el-table :data="otherDetailList" stripe>
            <el-table-column label="序号" type="index" align="center" />
            <el-table-column
              label="操作"
              prop="trafficMoney"
              width="70"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  icon="el-icon-minus"
                  round
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  @click.native.prevent="
                    deleteRow(scope.$index, otherDetailList, scope.row)
                  "
                />
              </template>
            </el-table-column>
            <el-table-column
              label="费用类型"
              prop="costType"
              align="center"
              width="200"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.costType"
                  filterable
                  placeholder="请选择"
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                >
                  <el-option
                    v-for="item in otherExpenseTypeList"
                    :key="item.key"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="时间"
              prop="startTime"
              align="center"
              width="300"
            >
              <template slot-scope="scope">
                <el-date-picker
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  v-model="scope.row.startTime"
                  align="right"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                  style="width: 80%"
                >
                </el-date-picker>
              </template>
            </el-table-column>
            <el-table-column
              label="发票类型"
              prop="invoiceType"
              align="center"
              width="300"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.invoiceType"
                  filterable
                  placeholder="请选择"
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                >
                  <el-option
                    v-for="item in invoiceTypeList"
                    :key="item.key"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="发票日期"
              prop="invoiceData"
              align="center"
              width="300"
            >
              <template slot-scope="scope">
                <el-date-picker
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  v-model="scope.row.invoiceData"
                  align="right"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                  style="width: 80%"
                >
                </el-date-picker>
              </template>
            </el-table-column>
            <el-table-column
              label="发票号码"
              prop="invoiceNumber"
              align="center"
              width="160"
            >
              <template slot-scope="scope">
                <el-input
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  v-model="scope.row.invoiceNumber"
                  type="number"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="发票金额"
              prop="invoiceMoney"
              align="center"
              width="160"
            >
              <template slot-scope="scope">
                <el-input
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  v-model="scope.row.invoiceMoney"
                  type="number"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="报销金额"
              prop="realityMoney"
              align="center"
              width="160"
            >
              <template slot-scope="scope">
                <el-input
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  v-model="scope.row.realityMoney"
                  type="number"
                  @input="moneyAllButton(otherDetailList, 'other')"
                />
              </template>
            </el-table-column>
            <!-- <el-table-column
              label="分摊人"
              prop="days"
              align="center"
              width="160"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.type"
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in projectCodeList"
                    :key="item.key"
                    :label="item.value"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="分摊金额"
              prop="money"
              align="center"
              width="140"
            >
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row.money"
                  width="20px"
                  size="small"
                  controls-position="right"
                  :precision="2"
                  :step="0.1"
                />
              </template>
            </el-table-column> -->
            <el-table-column
              label="备注"
              prop="remark"
              align="center"
              width="400"
            >
              <template slot-scope="scope">
                <el-input
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  v-model="scope.row.remark"
                  placeholder="请输入详细原因"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="附件"
              prop="frequency"
              align="center"
              width="200"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-upload
                  :disabled="scope.row.types == 3 || scope.row.types == 4"
                  class="upload-demo"
                  action=""
                  :on-remove="(file) => handleRemove(file, scope.row)"
                  :before-remove="beforeRemove"
                  :http-request="
                    (params) => handleFileChange(params, scope.row)
                  "
                  multiple
                  :file-list="scope.row.fileList || []"
                  :limit="6"
                  :on-exceed="handleExceed"
                  list-type="picture"
                >
                  <el-button size="mini" icon="el-icon-plus" round />
                </el-upload>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>
      <div
        slot="footer"
        class="dialog-footer"
        v-if="this.types == 1 || this.types == 2"
      >
        <el-button @click="cancelForm">取 消</el-button>
        <!-- @click="$refs.drawer.closeDrawer()" -->
        <el-button
          type="primary"
          @click="addFormButton(1)"
          :loading="loading"
          >{{ loading ? "提交中 ..." : "暂 存" }}</el-button
        >
        <el-button
          type="primary"
          @click="addFormButton(2)"
          :loading="loading"
          >{{ loading ? "提交中 ..." : "提 交" }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import moment from "moment";
import * as XLSX from "xlsx/xlsx.mjs";
import { getProjectByUseSignApi } from "@/api/work/project/project";
import {
  selectByIdInfoApi,
  bathAddListApi,
  processReviewStatusApi,
} from "@/api/work/project/reimburse";
import AWS from "aws-sdk";
import { getDaysBetween } from "@/utils/timeUtils";
export default {
  data() {
    return {
      processReviewFrom: {},
      trafficExpenseTypeList: [
        {
          key: 101,
          value: 101,
          label: "机车票(飞机)",
        },
        {
          key: 102,
          value: 102,
          label: "机车票(火车)",
        },
        {
          key: 103,
          value: 103,
          label: "机车票(高铁)",
        },
        {
          key: 104,
          value: 104,
          label: "机车票(客车/轮船)",
        },
        {
          key: 105,
          value: 105,
          label: "市内交通(的士)",
        },
        {
          key: 106,
          value: 106,
          label: "市内交通(地铁)",
        },
        {
          key: 107,
          value: 107,
          label: "市内交通(公交)",
        },
        {
          key: 108,
          value: 108,
          label: "市内交通(高速费)",
        },
      ],
      subsidyExpenseTypeList: [
        {
          key: 201,
          value: 201,
          label: "伙食补贴",
        },
        {
          key: 202,
          value: 202,
          label: "电话补贴",
        },
        {
          key: 203,
          value: 203,
          label: "交通补贴",
        },
      ],
      otherExpenseTypeList: [
        {
          key: 301,
          value: 301,
          label: "聚餐费",
        },
        {
          key: 302,
          value: 302,
          label: "活动经费",
        },
        {
          key: 303,
          value: 303,
          label: "办公室补贴",
        },
        {
          key: 304,
          value: 304,
          label: "零食费",
        },
        {
          key: 305,
          value: 305,
          label: "下午餐",
        },
        {
          key: 309,
          value: 309,
          label: "其他",
        },
      ],
      stayExpenseTypeList: [
        {
          key: 401,
          value: 401,
          label: "住宿费",
        },
      ],
      invoiceTypeList: [
        {
          key: 1,
          value: 1,
          label: "电子普通发票",
        },
        {
          key: 2,
          value: 2,
          label: "电子专用发票",
        },
        {
          key: 3,
          value: 3,
          label: "通用电子发票",
        },
        {
          key: 4,
          value: 4,
          label: "普通发票",
        },
        {
          key: 5,
          value: 5,
          label: "专用发票",
        },
        {
          key: 6,
          value: 6,
          label: "财政票据",
        },
      ],
      typeList: [
        {
          key: "traffic",
          value: "traffic",
          label: "交通",
        },
        {
          key: "subsidy",
          value: "subsidy",
          label: "补贴",
        },
        {
          key: "other",
          value: "other",
          label: "其他",
        },
        {
          key: "stay",
          value: "stay",
          label: "住宿",
        },
      ],
      // 补助明细
      subsidyDetailList: [],
      // 其他明细
      otherDetailList: [],
      // 住宿明细
      stayDetailList: [],
      // 交通明细
      trafficDetailList: [
        // {
        //   a: 1,
        //   fileList: [
        //     {
        //       name: "food.jpeg",
        //       url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        //     },
        //     {
        //       name: "food2.jpeg",
        //       url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        //     },
        //   ],
        // },
      ],

      titleMoney: {},
      activeNames: ["1"],
      projectCodeList: [],
      reimburseByList: [],
      dialog: false,
      loading: false,
      editShow: false,
      title: null,
      addEditForm: {
        id: null,
        category: 1,
        type: 1,
        times: this.dayjs(this.dayjs()).format("YYYY-MM-DD"),
      },
      timer: null,
      ids: null,
      types: 1,
      s3: new AWS.S3({
        // AWS 认证相关
        s3ForcePathStyle: true,
        signatureVersion: "v4",
        region: "us-east-1",
        forcePathStyle: true,
        endpoint: "http://175.178.33.21:9000",
        apiVersion: "2006-03-01",
        accessKeyId: "xQbvE7mVztnBwOze",
        secretAccessKey: "kWvenxWc3Fi3T7YwmiuBL5RSyPgn8YAk",
      }),
      paths: "project/",

      trafficAllMoney: 0,
      subsidyAllMoney: 0,
      otherAllMoney: 0,
      stayAllMoney: 0,
      addMoney: 0,
    };
  },
  methods: {
    // 1是新增，2是编辑，3是查看，4是审核
    show(id, type) {
      this.initData();
      this.types = type;
      this.dialog = true;
      if (type == 2 || type == 3 || type == 4) {
        this.selectByIdButton(id);
        this.editShow = true;
        this.title = "编辑";
      }
      if (type == 1) {
        this.addEditForm.id = null;
        this.title = "新增";
      }
    },
    initData() {
      // projectCodeList
      getProjectByUseSignApi().then((res) => {
        this.projectCodeList = res.result;
      });
    },

    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then((_) => {
          this.cancelForm();
          this.dialog = false;
        })
        .catch((_) => {});
    },
    cancelForm() {
      this.processReviewFrom.opinion = null;
      this.loading = false;
      this.dialog = false;
      this.editShow = false;
      this.addEditForm.timeSolt = [];
      this.subsidyDetailList = [];
      this.otherDetailList = [];
      this.stayDetailList = [];
      this.trafficDetailList = [];
      this.$refs["addEditForm"].resetFields();
      this.$parent.selectDataListButton();
    },
    addFormButton(type) {
      this.addEditForm.action = type;
      this.addEditForm.startTime = this.addEditForm.timeSolt[0];
      this.addEditForm.endTime = this.addEditForm.timeSolt[1];
      this.addEditForm.projectCode = this.addEditForm.projectCode.code;
      this.addEditForm.subsidyDetailList = this.subsidyDetailList;
      this.addEditForm.otherDetailList = this.otherDetailList;
      this.addEditForm.stayDetailList = this.stayDetailList;
      this.addEditForm.trafficDetailList = this.trafficDetailList;
      console.log(this.addEditForm);
      bathAddListApi(this.addEditForm).then((res) => {
        if (type === 1) {
          this.addEditForm.id = res.result.id;
          this.addEditForm.code = res.result.code;
          this.addEditForm.projectCode = {
            code: this.addEditForm.projectCode,
          };
        } else {
          this.cancelForm();
        }
      });
    },
    stagingButton() {},
    //查询详情
    selectByIdButton(row) {
      selectByIdInfoApi({ code: row, types: this.types }).then((res) => {
        this.addEditForm = res.result;

        this.addEditForm.projectCode = {
          code: res.result.projectCode,
        };

        this.$set(this.addEditForm, "timeSolt", [
          res.result.startTime,
          res.result.endTime,
        ]);

        this.subsidyDetailList = res.result.subsidyDetailList;
        this.otherDetailList = res.result.otherDetailList;
        this.stayDetailList = res.result.stayDetailList;
        this.trafficDetailList = res.result.trafficDetailList;
        this.moneyAllButton(this.subsidyDetailList, "subsidy");
        this.moneyAllButton(this.otherDetailList, "other");
        this.moneyAllButton(this.stayDetailList, "stay");
        this.moneyAllButton(this.trafficDetailList, "traffic");
      });
    },
    addDialogClosed() {
      // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs["addEditForm"].resetFields();
    },
    dealTypeButton(row) {
      if (row != "2") {
        this.addEditForm.reimburseBy = null;
        this.addEditForm.email = null;
        this.addEditForm.phone = null;
      }
    },
    handleChangeTab(val) {
      console.log(val);
    },
    otherAddButton(event) {
      event.stopPropagation();
      event.preventDefault();
      var otherData = {
        costType: 309,
        fileList: [],
      };
      this.otherDetailList.push(otherData);
    },
    subsidyAddButton(event) {
      event.stopPropagation();
      event.preventDefault();
      var otherData = {
        costType: 201,
        fileList: [],
      };
      this.subsidyDetailList.push(otherData);
    },
    stayAddButton(event) {
      event.stopPropagation();
      event.preventDefault();
      var otherData = {
        costType: 401,
        fileList: [],
      };
      this.stayDetailList.push(otherData);
    },
    trafficAddButton(event) {
      event.stopPropagation();
      event.preventDefault();
      var otherData = {
        costType: 105,
        fileList: [],
      };
      this.trafficDetailList.push(otherData);
    },
    deleteRow(index, dataList, row) {
      dataList.splice(index, 1);
    },
    //点击移除之后的参数
    handleRemove(file, row) {
      var that = this;
      const index = row.fileList.findIndex((item) => item === file);
      if (index !== -1) {
        const newFileList = [...row.fileList];
        newFileList.splice(index, 1);
        row.fileList = newFileList;
      }
      var params = {
        Bucket: "xun", // 存储桶名称
        Key: file.dataKey,
      };
      //还需要在minio上面删除
      that.s3.deleteObject(params, function (err, data) {
        if (err) {
          console.log("发生错误：", err.code, err.message);
        } else {
          console.log("删除成功");
        }
      });
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleFileChange(e, row) {
      console.log("e", e.target);
      console.log("e", row);
      var that = this;
      let file = e.file;
      console.log("file change", file);
      if (file) {
        console.log("====");
        that.videoFileName = file.name;
        that.videoLoading = true;
        that.videoSuffix = that.videoFileName.split(".")[1];

        that.videoSuffixName = that.videoFileName.split(".")[0];
        console.log(that.videoSuffix);
        // '/food/'+

        var keyName = new Date().getTime() + that.videoSuffixName;
        var key = keyName + "." + that.videoSuffix;
        var params = {
          Bucket: "xun", // 存储桶名称
          Key: this.paths + key, // 文件名，重名会覆盖
          Body: file,
        };
        that.videoUpload = that.s3.putObject(params, function (err, data) {
          if (err) {
            console.log("发生错误：", err.code, err.message);
            that.videoLoading = false;
          } else {
            var newParams = {
              Bucket: "xun", // 存储桶名称
              Key: that.paths + key, // 文件名，重名会覆盖
              Expires: 36000,
            };
            that.s3.getSignedUrl("getObject", newParams, (err, url) => {
              if (err) {
                console.error(err);
                return;
              }
              // 设置预览URL
              file.url = url;
            });
            file.fileName = key;
            file.fileType = file.type;
            file.fileSize = file.size;
            file.sign = data.ETag;
            file.dataKey = params.Key;
            if (row.fileList == null) {
              const newFile = [];
              newFile.push(file);
              row.fileList = newFile;
            } else {
              row.fileList.push(file);
            }
          }
        });
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 6 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    getProjectButton(val) {
      console.log(val);
      this.addEditForm.number = val.number;
    },
    processReviewButton(type) {
      this.processReviewFrom.status = type;
      this.processReviewFrom.reimburseCode = this.addEditForm.code;
      processReviewStatusApi(this.processReviewFrom).then((res) => {
        this.cancelForm();
      });
    },
    moneyAllButton(dataList, type) {
      if (dataList == null || dataList.length == 0) {
        return;
      }
      let allMoney = dataList.reduce(
        (sum, item) => sum + parseFloat(item.realityMoney || 0),
        0
      );
      allMoney = allMoney.toFixed(2);

      if (type === "traffic") {
        this.trafficAllMoney = allMoney;
      } else if (type === "subsidy") {
        this.subsidyAllMoney = allMoney;
      } else if (type === "other") {
        this.otherAllMoney = allMoney;
      } else if (type === "stay") {
        this.stayAllMoney = allMoney;
      }
      let allMoneys =
        parseFloat(this.stayAllMoney || 0) +
        parseFloat(this.otherAllMoney || 0) +
        parseFloat(this.subsidyAllMoney || 0) +
        parseFloat(this.trafficAllMoney || 0);
      this.addMoney = parseFloat(allMoneys || 0).toFixed(2);
    },

    handleDateChange(row, value, index, dataList) {
      const date1 = new Date(value[0]);
      const date2 = new Date(value[1]);
      const dayss = getDaysBetween(date1, date2) + 1;
      this.$set(dataList, index, {
        ...dataList[index],
        days: dayss,
        realityMoney: dayss * 60,
      });
    },
    subsidyDaysButton(row) {
      row.realityMoney = row.days * 60;
    },
    handleExcelFile(event) {
      const file = event.target.files[0];
      console.log("=-", file);
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        let jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const is1904 = workbook.Workbook.WBProps.date1904;
        let trafficDetailListFile = [];
        let stayDetailListFile = [];
        let subsidyDetailListFile = [];
        let otherDetailListFile = [];

        jsonData.forEach((row) => {
          this.handleExcelFileData(
            row,
            trafficDetailListFile,
            stayDetailListFile,
            subsidyDetailListFile,
            otherDetailListFile,
            is1904
          );
        });

        this.$refs.fileInput.value = "";
        this.stayDetailList = stayDetailListFile;
        this.subsidyDetailList = subsidyDetailListFile;
        this.otherDetailList = otherDetailListFile;
        this.trafficDetailList = trafficDetailListFile;
        //并且同时重新触发计算
        this.moneyAllButton(this.otherDetailList, "other");
        this.moneyAllButton(this.subsidyDetailList, "subsidy");
        this.moneyAllButton(this.trafficDetailList, "traffic");
        this.moneyAllButton(this.stayDetailList, "stay");
      };
      reader.readAsArrayBuffer(file);
    },
    handleExcelFileData(
      row,
      trafficDetailListFile,
      stayDetailListFile,
      subsidyDetailListFile,
      otherDetailListFile,
      is1904
    ) {
      if (row[0] == "交通") {
        const datatFile = this.transformData(row, "traffic", is1904);
        trafficDetailListFile.push(datatFile);
      } else if (row[0] == "补贴") {
        const datatFile = this.transformData(row, "subsidy", is1904);
        subsidyDetailListFile.push(datatFile);
      } else if (row[0] == "其他") {
        const datatFile = this.transformData(row, "other", is1904);
        otherDetailListFile.push(datatFile);
      } else if (row[0] == "住宿") {
        const datatFile = this.transformData(row, "stay", is1904);
        stayDetailListFile.push(datatFile);
      }
    },
    transformData(row, type, is1904) {
      console.log("交通", row);
      let costType = row[1] == null ? null : row[1];
      let invoiceTypeName = row[2] == null ? null : row[2];
      let invoiceType = null;
      let datss = null;
      let remark = row[11] == null ? null : row[11];
      let money = row[10] == null ? null : row[10];
      let startTime = row[3] == null ? null : row[3];
      let endTime = row[4] == null ? null : row[4];
      let startAddress = row[5] == null ? null : row[5];
      let endAddress = row[6] == null ? null : row[6];
      let invoiceData = row[7] == null ? null : row[7];
      let invoiceNumber = row[8] == null ? null : row[8];
      let invoiceMoney = row[9] == null ? null : row[9];
      let isRaper = null;
      // 计算日期和时间的偏移量（以毫秒为单位）
      const baseDate = is1904 ? new Date(1904, 0, 1) : new Date(1900, 0, 0);
      const offset = baseDate.getTime();
      //发票时间
      if (null != invoiceData) {
        const invoiceDataTime = invoiceData * 24 * 60 * 60 * 1000 + offset;
        const invoiceDataData = new Date(invoiceDataTime);
        invoiceData = moment(invoiceDataData).format("YYYY-MM-DD");
      }
      if (invoiceTypeName != null) {
        if (!invoiceTypeName.includes("电子")) {
          isRaper = 1;
        }
        invoiceType = this.invoiceTypeList.find(
          (item) => item.label == invoiceTypeName
        ).key;
      }
      let timeSolt = [];
      let startTimeDataName = null;
      let endTimesDataName = null;
      // 开始跟结束
      if (null != startTime) {
        const startTimes = startTime * 24 * 60 * 60 * 1000 + offset;
        const startTimeData = new Date(startTimes);
        if (type == "traffic") {
          startTimeDataName = moment(startTimeData).format("YYYY-MM-DD HH:mm");
        } else {
          startTimeDataName = moment(startTimeData).format("YYYY-MM-DD HH:mm");
        }
        //
      }
      if (null != endTime) {
        const endTimes = endTime * 24 * 60 * 60 * 1000 + offset;
        const endTimesData = new Date(endTimes);
        if (type == "traffic") {
          endTimesDataName = moment(endTimesData).format("YYYY-MM-DD HH:mm");
        } else {
          endTimesDataName = moment(endTimesData).format("YYYY-MM-DD HH:mm");
        }
      }

      if ((endTimesDataName != null) & (startTimeDataName != null)) {
        timeSolt.push(endTimesDataName);
        timeSolt.push(startTimeDataName);
        const date1 = new Date(endTimesDataName);
        const date2 = new Date(startTimeDataName);
        datss = getDaysBetween(date1, date2) + 1;
      }

      //这里处理样式
      if (type == "traffic") {
        costType = this.trafficExpenseTypeList.find(
          (item) => item.label == costType
        ).key;
      } else if (type == "stay") {
        costType = 401;
      } else if (type == "other") {
        costType = this.otherExpenseTypeList.find(
          (item) => item.label == costType
        ).key;
      } else if (type == "subsidy") {
        console.log('====================',costType)
        costType = this.subsidyExpenseTypeList.find(
          (item) => item.label == costType
        ).key;
      }

      var data = {
        days: datss,
        costType: costType,
        invoiceType: invoiceType,
        timeSolt: timeSolt,
        realityMoney: money,
        remark: remark,
        isRaper: isRaper,
        startTime: startTimeDataName,
        startTimes: startTimeDataName,
        startAddress: startAddress,
        endAddress: endAddress,
        invoiceData: invoiceData,
        invoiceNumber: invoiceNumber,
        invoiceMoney: invoiceMoney,
      };
      return data;
    },
  },
};
</script>
<style  lang="less" scoped>
.my-label {
  background: #e1f3d8;
}

.my-content {
  background: #fde2e2;
}

.custom-upload-button {
  display: inline-block;
  overflow: hidden;
  position: relative;
  margin: -15px 20px;
}

.custom-upload-button button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.custom-upload-button input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.Abutton {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  text-decoration: none;
  border: none;
  cursor: pointer;
}
</style>



