export function getDaysBetween(date1, date2) {
    const ONE_DAY = 1000 * 60 * 60 * 24; // 一天的毫秒数
    const date1Time = date1.getTime(); // 获取时间戳
    const date2Time = date2.getTime();

    const difference = Math.abs(date1Time - date2Time); // 获取时间差
    return Math.round(difference / ONE_DAY); // 两个日期之间的天数
}


export function getAllMonths(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const months = [];
    let current = start;
    while (current <= end) {
        const year = current.getFullYear();
        const month = current.getMonth() + 1;
        months.push(`${year}-${month.toString().padStart(2, '0')}`);
        current.setMonth(current.getMonth() + 1);
    }
    return months;
}

//获取二个时间的月份 不包括当月的
export function getAllMonthsNoCurrent(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const months = [];

    const currentDate = new Date(); // 获取当前日期

    let current = new Date(start.getFullYear(), start.getMonth(), 1);

    while (current <= end && current <= currentDate) {
        const month = current.getMonth() + 1;
        //这里调整改动返回 todo看看考虑怎么做适配
        months.push(`${month}月`);

        current.setMonth(current.getMonth() + 1);
    }

    return months;
}